import React from "react"

import { List, ListItem } from "@kiwicom/orbit-components"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@kiwicom/orbit-components/lib/Table"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Pay & Overtime">
    <Hero title="Pay & Overtime" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Structure">
          <EmberCardSection>
            <Stack>
              <Text>
                We have a transparent approach to pay. We believe the
                transparency is important when it comes to creating a trusting
                and fair environment.
              </Text>
              <Text>
                Your basic wages are paid weekly in arrears, less any statutory
                and voluntary deductions. This basic payment is based on your
                contracted hours (40 hours per week for full-time drivers).
              </Text>
              <Text>
                If you start (or return from a period of unpaid leave) part way
                through the week, you'll be paid a pro-rata amount for that full
                week. This is regardless of number of hours actually worked.
              </Text>
              <Text>
                Every 13 weeks, we'll review your hours to check that you
                haven't worked more than your contracted hours, taking account
                of any late finishes but excluding overtime (which is paid
                separately – see below). If you have worked extra hours, we'll
                make a balancing payment at your regular pay rate to cover the
                difference.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Current Rates of Pay">
          <EmberCardSection>
            <Stack>
              <Text>
                Pay rates vary based on your time at Ember and your role as
                outlined below.
              </Text>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell as="th" scope="col">
                      Level
                    </TableCell>
                    <TableCell as="th" scope="col">
                      2023/24 Pay
                    </TableCell>
                    <TableCell as="th" scope="col">
                      Increase %
                    </TableCell>
                    <TableCell as="th" scope="col">
                      Increase £
                    </TableCell>
                    <TableCell as="th" scope="col">
                      2024/25 Pay
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell as="th" scope="row">
                      Driver
                    </TableCell>
                    <TableCell>£14.10</TableCell>
                    <TableCell>4.0%</TableCell>
                    <TableCell>£0.56</TableCell>
                    <TableCell>£14.66</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell as="th" scope="row">
                      Driver after 1 year at Ember
                    </TableCell>
                    <TableCell>£14.70</TableCell>
                    <TableCell>4.0%</TableCell>
                    <TableCell>£0.59</TableCell>
                    <TableCell>£15.29</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell as="th" scope="row">
                      Hub Support/Driver Ambassador
                    </TableCell>
                    <TableCell>£15.29</TableCell>
                    <TableCell>4.0%</TableCell>
                    <TableCell>£0.61</TableCell>
                    <TableCell>£15.90</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Text italic>
                Note that relief drivers are not eligible for increases based on
                the time spent with Ember. The time at Ember counts continous
                employment and there are no further increases beyond a year. Hub
                Support and Driver Ambassodors do not get a further increase
                based on time at Ember.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Pay Reviews">
          <EmberCardSection>
            <Stack>
              <Text>
                We will review pay rates annually and generally aim to ensure
                pay keeps up with inflation and the wider market. The last
                review was in May 2024 so the next review will be in May 2025.
              </Text>
              <Text>
                During a review we will check the CPIH (Consumer Prices Index
                with Housing - this is a measure of inflation that includes
                housing costs) which are provided by the Office for National
                Statistics – along with benchmarking Ember against other
                companies in the area.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Overtime">
          <EmberCardSection>
            <Stack>
              <Text>
                If we offer a shift at short notice and you choose to accept it,
                this will be counted as overtime rather than counting towards
                your basic hours. That means it will be paid in the next pay run
                after it was worked as additional overtime pay.
              </Text>
              <Text>
                We may choose to offer an increased rate when you are offered
                overtime at particularly short notice. We'll generally follow
                the rates below but be sure to clarify when offered a shift if
                in any doubt.
              </Text>
              <List>
                <ListItem>More than 48 hours notice: 1.2x pay</ListItem>
                <ListItem>24 - 48 hours notice: 1.5x pay</ListItem>
                <ListItem>Less than 24 hours notice: 2x pay</ListItem>
              </List>
              <Text>
                In exceptional circumstances, we may vary these rates (e.g. if
                we've asked you to return from holiday to cover a shift we might
                offer to cover costs), but this will be very rare.
              </Text>
              <Text>
                Relief drivers will be paid at a standard hourly rate as per
                their contracts (shown as 1x) unless offered an enhanced rate.
              </Text>

              <Text>
                If you work on Christmas Day, New Year’s Eve or New Year’s Day,
                you will be paid an overtime rate of 3x your normal pay.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
